/* eslint max-len: 0 */
import Enum from 'enum';
import { Resources as Credentialing } from '@/services/shiftkeyAPI/resources/ResourcesV2Credentialing';
import { Resources as PaymentsAndBilling } from '@/services/shiftkeyAPI/resources/ResourcesV2PaymentsAndBilling';
import { Resources as ClientFacilityTeamShift } from '@/services/shiftkeyAPI/resources/ResourcesV2ClientFacilityTeamShift';

/**
 * Enum defining all available api v2 resources and their URL template
 */

const CredentialingRoutes = Object.fromEntries(Credentialing.enums.map((e) => [e.key, e.value]));
const PaymentsAndBillingsRoutes = Object.fromEntries(PaymentsAndBilling.enums.map((e) => [e.key, e.value]));
const ClientFacilityTeamShiftRoutes = Object.fromEntries(ClientFacilityTeamShift.enums.map((e) => [e.key, e.value]));
export const ResourcesV2 = new Enum({
  InsightsScheduledHours: 'insights/scheduled-hours',
  UserShiftDigitalInvoice: 'users/:userId/shifts/:shiftId/digital_invoice',
  UserShiftDigitalInvoicePreview: 'users/:userId/shifts/:shiftId/digital_invoice/preview',
  UserTouchPointCategories: 'users/:userId/touch_point_categories',
  UserTouchPointCategory: 'users/:userId/touch_point_categories/:categoryName',
  UserStates: 'users/:userId/states',
  AppLaunchAlert: 'user/app_launch',
  ...CredentialingRoutes,
  ...PaymentsAndBillingsRoutes,
  ...ClientFacilityTeamShiftRoutes,
});

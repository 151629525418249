export const IN_APP_NOTIFICATION = 1;
export const BILLING_OVERVIEW = 3;
export const RATE_OVERRIDE_EXPERIMENT = 5;
export const IN_APP_CANCELLATION = 7;
export const IN_APP_CANCELLATION_EA_SAMI = 9;
export const IN_ADVANCE_PAYOUTS = 12;
export const IN_APP_CANCELLATION_2_HOUR_DEADLINE = 15;
export const FACILITY_RATINGS = 16;
export const CLIENT_APP_DASHBOARD = 19;
export const FLEXIBLE_PREMIUM_RATES = 25;
export const SHIFT_DISPATCH_PHASE_4 = 26;
export const EMERGENCY_BROADCAST_REDESIGN_MODAL_WIND_DOWN = 27;
export const EMERGENCY_BROADCAST_REDESIGN = 28;

export const flags = [
  {
    id: IN_APP_NOTIFICATION,
    name: 'In-App Notification',
  },
  {
    id: BILLING_OVERVIEW,
    name: 'Billing Overview',
  },
  {
    id: RATE_OVERRIDE_EXPERIMENT,
    name: 'Rate Override Experiment',
  },
  {
    id: IN_APP_CANCELLATION,
    name: 'In-App Cancellation',
  },
  {
    id: IN_APP_CANCELLATION_EA_SAMI,
    name: 'In-App Cancellation With EA and SAMI',
  },
  {
    id: IN_ADVANCE_PAYOUTS,
    name: 'Provider In Advance Payments',
  },
  {
    id: IN_APP_CANCELLATION_2_HOUR_DEADLINE,
    name: '2 Hour In-App Cancellation Deadline Test',
  },
  {
    id: FACILITY_RATINGS,
    name: 'Facility Ratings',
  },
  {
    id: CLIENT_APP_DASHBOARD,
    name: 'Client App Dashboard',
  },
  {
    id: FLEXIBLE_PREMIUM_RATES,
    name: 'Flexible Premium Rates',
  },
  {
    id: SHIFT_DISPATCH_PHASE_4,
    name: 'Shift Dispatch Phase 4',
  },
  {
    id: EMERGENCY_BROADCAST_REDESIGN_MODAL_WIND_DOWN,
    name: 'Emergency Broadcast Redesign Modal Wind Down',
  },
  {
    id: EMERGENCY_BROADCAST_REDESIGN,
    name: 'Emergency Broadcast Redesign',
  },
];

import {
  MANAGE_EMPLOYEE_PERMISSIONS,
  VIEW_EMPLOYEE_PERMISSIONS,
  MODULE_SCHEDULE_MANAGE,
  MODULE_SCHEDULE_VIEW,
  MODULE_USERS_MANAGE,
  MODULE_USERS_VIEW,
  MODULE_TEAMS_MANAGE,
  MODULE_TEAMS_VIEW,
  MODULE_REPORTS_MANAGE,
  MODULE_REPORTS_VIEW,
  MODULE_PAYABLES_MANAGE,
  MODULE_PAYABLES_VIEW,
  MODULE_BILLING_MANAGE,
  MODULE_BILLING_VIEW,
  MODULE_ONBOARDING_MANAGE,
  MODULE_ONBOARDING_VIEW,
  MODULE_INSIGHTS_MANAGE,
  MODULE_INSIGHTS_VIEW,
  MODULE_HELP_MANAGE,
  MODULE_HELP_VIEW,
  MODULE_DOWNLOAD_MANAGE,
  MODULE_DOWNLOAD_VIEW,
  MODULE_MESSAGING_MANAGE,
  MODULE_MESSAGING_VIEW,
  MODULE_SETTINGS_MANAGE,
  MODULE_SETTINGS_VIEW,
  MODULE_SETTINGS_FACILITY_FEATURE_FLAG_MANAGE,
  MODULE_SETTINGS_FACILITY_FEATURE_FLAG_VIEW,
  MODULE_USERS_PROVIDER_MANAGE,
  MODULE_USERS_PROVIDER_VIEW,
  MODULE_USERS_PROVIDER_DEACTIVATE,
  MODULE_USERS_PROVIDER_DNR,
  MODULE_USERS_CLIENT_MANAGE,
  MODULE_USERS_CLIENT_VIEW,
  MODULE_USERS_EMPLOYEE_MANAGE,
  MODULE_USERS_EMPLOYEE_VIEW,
  MODULE_USERS_ONBOARDING_MANAGE,
  MODULE_USERS_ONBOARDING_VIEW,
  MODULE_SHIFTS_WRITE,
  MODULE_SHIFTS_MANAGE,
  MODULE_SHIFTS_VIEW,
  MODULE_SHIFTS_FORCE_ASSIGN,
  MODULE_SHIFTS_REQUESTED_SHIFTS_MANAGE,
  MODULE_SHIFTS_REQUESTED_SHIFTS_VIEW,
  MODULE_SHIFTS_TIMESHEETS_MANAGE,
  MODULE_SHIFTS_TIMESHEETS_VIEW,
  MODULE_SHIFTS_PROVIDER_INVOICES_MANAGE,
  MODULE_SHIFTS_PROVIDER_INVOICES_VIEW,
  MODULE_SHIFTS_UNRESOLVED_SHIFTS_MANAGE,
  MODULE_SHIFTS_UNRESOLVED_SHIFTS_VIEW,
  MODULE_REPORTS_SHIFTS_REPORT_MANAGE,
  MODULE_REPORTS_SHIFTS_REPORT_VIEW,
  MODULE_REPORTS_PPD_REPORT_MANAGE,
  MODULE_REPORTS_PPD_REPORT_VIEW,
  MODULE_PAYABLES_UNPAID_MANAGE,
  MODULE_PAYABLES_UNPAID_VIEW,
  MODULE_PAYABLES_PAID_MANAGE,
  MODULE_PAYABLES_PAID_VIEW,
  MODULE_PAYABLES_CREDITS_MANAGE,
  MODULE_PAYABLES_CREDITS_VIEW,
  MODULE_PAYABLES_DEDUCTIONS_MANAGE,
  MODULE_PAYABLES_DEDUCTIONS_VIEW,
  MODULE_BILLING_INVOICES_MANAGE,
  MODULE_BILLING_INVOICES_VIEW,
  MODULE_BILLING_CREDITS_MANAGE,
  MODULE_BILLING_CREDITS_VIEW,
  MODULE_BILLING_CHARGES_MANAGE,
  MODULE_BILLING_CHARGES_VIEW,
  MODULE_ONBOARDING_GENERAL_LICENSING_MANAGE,
  MODULE_ONBOARDING_GENERAL_LICENSING_VIEW,
  MODULE_ONBOARDING_GENERAL_LICENSING_DRAIN_MANUAL_QUEUE,
  MODULE_ONBOARDING_NURSE_LICENSING_MANAGE,
  MODULE_ONBOARDING_NURSE_LICENSING_VIEW,
  MODULE_ONBOARDING_DOCUMENTS_MANAGE,
  MODULE_ONBOARDING_DOCUMENTS_VIEW,
  MODULE_ONBOARDING_DRUG_SCREENS_MANAGE,
  MODULE_ONBOARDING_DRUG_SCREENS_VIEW,
  MODULE_ONBOARDING_BACKGROUND_CHECKS_MANAGE,
  MODULE_ONBOARDING_BACKGROUND_CHECKS_VIEW,
  MODULE_ONBOARDING_PAYMENTS_METHODS_MANAGE,
  MODULE_ONBOARDING_PAYMENTS_METHODS_VIEW,
  MODULE_INSIGHTS_HOURS_MANAGE,
  MODULE_INSIGHTS_HOURS_VIEW,
  MODULE_INSIGHTS_REVENUE_MANAGE,
  MODULE_INSIGHTS_REVENUE_VIEW,
  MODULE_INSIGHTS_REGIONS_MANAGE,
  MODULE_INSIGHTS_REGIONS_VIEW,
  MODULE_INSIGHTS_ONBOARDING_MANAGE,
  MODULE_INSIGHTS_ONBOARDING_VIEW,
  MODULE_INSIGHTS_COMMISSION_MANAGE,
  MODULE_INSIGHTS_COMMISSION_VIEW,
  MODULE_INSIGHTS_CREDIT_LIMITS_MANAGE,
  MODULE_INSIGHTS_CREDIT_LIMITS_VIEW,
  MODULE_INSIGHTS_FACILITY_MAP_MANAGE,
  MODULE_INSIGHTS_FACILITY_MAP_VIEW,
  MODULE_INSIGHTS_SCHEDULED_HOURS_MANAGE,
  MODULE_INSIGHTS_SCHEDULED_HOURS_VIEW,
  MODULE_INSIGHTS_WORK_LOGS_MANAGE,
  MODULE_INSIGHTS_WORK_LOGS_VIEW,
  MODULE_INSIGHTS_MESSAGING_MANAGE,
  MODULE_INSIGHTS_MESSAGING_VIEW,
  MODULE_INSIGHTS_RESOLUTIONS_MANAGE,
  MODULE_INSIGHTS_RESOLUTIONS_VIEW,
  MODULE_INSIGHTS_VALIDATION_MANAGE,
  MODULE_INSIGHTS_VALIDATION_VIEW,
  MODULE_SETTINGS_SPECIALTIES_MANAGE,
  MODULE_SETTINGS_SPECIALTIES_VIEW,
  MODULE_SETTINGS_SKILLS_MANAGE,
  MODULE_SETTINGS_SKILLS_VIEW,
  MODULE_SETTINGS_CREDENTIALS_MANAGE,
  MODULE_SETTINGS_CREDENTIALS_VIEW,
  MODULE_SETTINGS_CREDENTIAL_EXPLAINER_VIEW,
  MODULE_SETTINGS_REGIONS_MANAGE,
  MODULE_SETTINGS_REGIONS_VIEW,
  MODULE_SETTINGS_HOLIDAYS_VIEW,
  MODULE_SETTINGS_HOLIDAYS_MANAGE,
  MODULE_SETTINGS_MARKETERS_MANAGE,
  MODULE_SETTINGS_MARKETERS_VIEW,
  MODULE_SETTINGS_AR_FACTORING_MANAGE,
  MODULE_SETTINGS_AR_FACTORING_VIEW,
  MODULE_SETTINGS_PAYOUT_DEDUCTIONS_MANAGE,
  MODULE_SETTINGS_PAYOUT_DEDUCTIONS_VIEW,
  MODULE_SETTINGS_ROLES_MANAGE,
  MODULE_SETTINGS_ROLES_VIEW,
  MODULE_SETTINGS_MISC_MANAGE,
  MODULE_SETTINGS_DEMO_GENERATOR_MANAGE,
  LEVEL_1,
  LEVEL_2,
  LEVEL_3
} from '@/data/Employee/Permissions';

export class EmployeeService {
  constructor(userData = null) {
    this.userData = userData || window.user;
  }

  permissions() {
    return this.userData?.permissions;
  }

  checkPermission(permission) {
    return this.permissions()?.includes(permission);
  }

  /// /////////////////////////////////////////////////////////////////////////
  ///
  /// CUSTOM PERMISSIONS
  ///
  /// /////////////////////////////////////////////////////////////////////////

  canManageEmployeePermissions() {
    return this.checkPermission(MANAGE_EMPLOYEE_PERMISSIONS);
  }

  canViewEmployeePermissions() {
    return this.canManageEmployeePermissions() || this.checkPermission(VIEW_EMPLOYEE_PERMISSIONS);
  }

  /// /////////////////////////////////////////////////////////////////////////
  ///
  /// MODULES PERMISSIONS
  ///
  /// /////////////////////////////////////////////////////////////////////////
  canManageSchedule() {
    return this.checkPermission(MODULE_SCHEDULE_MANAGE);
  }

  canViewSchedule() {
    return this.canManageSchedule() || this.checkPermission(MODULE_SCHEDULE_VIEW);
  }

  // Users
  canManageUsers() {
    return this.checkPermission(MODULE_USERS_MANAGE);
  }

  canViewUsers() {
    return this.canManageUsers()
      || this.checkPermission(MODULE_USERS_VIEW);
  }

  canManageUsersProviders() {
    return this.checkPermission(MODULE_USERS_PROVIDER_MANAGE)
      || this.canManageUsers();
  }

  canViewUsersProviders() {
    return this.canManageUsersProviders()
      || this.checkPermission(MODULE_USERS_PROVIDER_VIEW)
      || this.canViewUsers();
  }

  canDeactivateUsersProviders() {
    return this.checkPermission(MODULE_USERS_PROVIDER_DEACTIVATE);
  }

  canDnrUsersProviders() {
    return this.checkPermission(MODULE_USERS_PROVIDER_DNR);
  }

  canManageUsersClients() {
    return this.checkPermission(MODULE_USERS_CLIENT_MANAGE)
      || this.canManageUsers();
  }

  canViewUsersClients() {
    return this.canManageUsersClients()
      || this.checkPermission(MODULE_USERS_CLIENT_VIEW)
      || this.canViewUsers();
  }

  canManageUsersEmployees() {
    return this.checkPermission(MODULE_USERS_EMPLOYEE_MANAGE)
      || this.canManageUsers();
  }

  canViewUsersEmployees() {
    return this.canManageUsersEmployees()
      || this.checkPermission(MODULE_USERS_EMPLOYEE_VIEW)
      || this.canViewUsers();
  }

  canManageUsersOnboarding() {
    return this.checkPermission(MODULE_USERS_ONBOARDING_MANAGE)
      || this.canManageUsers();
  }

  canViewUsersOnboarding() {
    return this.canManageUsersOnboarding()
      || this.checkPermission(MODULE_USERS_ONBOARDING_VIEW)
      || this.canViewUsers();
  }

  // Teams
  canManageTeams() {
    return this.checkPermission(MODULE_TEAMS_MANAGE);
  }

  canViewTeams() {
    return this.canManageTeams() || this.checkPermission(MODULE_TEAMS_VIEW);
  }

  // Shifts
  canManageShifts() {
    return this.checkPermission(MODULE_SHIFTS_MANAGE);
  }

  canWriteShifts() {
    return this.checkPermission(MODULE_SHIFTS_WRITE);
  }

  canViewShifts() {
    return this.canManageShifts() || this.checkPermission(MODULE_SHIFTS_VIEW);
  }

  canForceAssignShifts() {
    return this.checkPermission(MODULE_SHIFTS_FORCE_ASSIGN);
  }

  canManageShiftsRequestedShifts() {
    return this.checkPermission(MODULE_SHIFTS_REQUESTED_SHIFTS_MANAGE)
      || this.canManageShifts();
  }

  canViewShiftsRequestedShifts() {
    return this.canManageShiftsRequestedShifts()
      || this.checkPermission(MODULE_SHIFTS_REQUESTED_SHIFTS_VIEW)
      || this.canViewShifts();
  }

  canManageShiftsTimesheets() {
    return this.checkPermission(MODULE_SHIFTS_TIMESHEETS_MANAGE)
      || this.canManageShifts();
  }

  canViewShiftsTimesheets() {
    return this.canManageShiftsTimesheets()
      || this.checkPermission(MODULE_SHIFTS_TIMESHEETS_VIEW)
      || this.canViewShifts();
  }

  canManageShiftsProviderInvoices() {
    return this.checkPermission(MODULE_SHIFTS_PROVIDER_INVOICES_MANAGE)
      || this.canManageShifts();
  }

  canViewShiftsProviderInvoices() {
    return this.canManageShiftsProviderInvoices()
      || this.checkPermission(MODULE_SHIFTS_PROVIDER_INVOICES_VIEW)
      || this.canViewShifts();
  }

  canManageShiftsUnresolvedShifts() {
    return this.checkPermission(MODULE_SHIFTS_UNRESOLVED_SHIFTS_MANAGE)
      || this.canManageShifts();
  }

  canViewShiftsUnresolvedShifts() {
    return this.canManageShiftsUnresolvedShifts()
      || this.checkPermission(MODULE_SHIFTS_UNRESOLVED_SHIFTS_VIEW)
      || this.canViewShifts();
  }

  // Reports
  canManageReports() {
    return this.checkPermission(MODULE_REPORTS_MANAGE);
  }

  canViewReports() {
    return this.canManageReports() || this.checkPermission(MODULE_REPORTS_VIEW);
  }

  canManageReportsShiftsReport() {
    return this.checkPermission(MODULE_REPORTS_SHIFTS_REPORT_MANAGE)
      || this.canManageReports();
  }

  canViewReportsShiftsReport() {
    return this.canManageReportsShiftsReport()
      || this.checkPermission(MODULE_REPORTS_SHIFTS_REPORT_VIEW)
      || this.canViewReports();
  }

  canManageReportsPpdReport() {
    return this.checkPermission(MODULE_REPORTS_PPD_REPORT_MANAGE)
      || this.canManageReports();
  }

  canViewReportsPpdReport() {
    return this.canManageReportsPpdReport()
      || this.checkPermission(MODULE_REPORTS_PPD_REPORT_VIEW)
      || this.canViewReports();
  }

  // Payables
  canManagePayables() {
    return this.checkPermission(MODULE_PAYABLES_MANAGE);
  }

  canViewPayables() {
    return this.canManagePayables() || this.checkPermission(MODULE_PAYABLES_VIEW);
  }

  canManagePayablesUnpaid() {
    return this.checkPermission(MODULE_PAYABLES_UNPAID_MANAGE)
      || this.canManagePayables();
  }

  canViewPayablesUnpaid() {
    return this.canManagePayablesUnpaid()
      || this.checkPermission(MODULE_PAYABLES_UNPAID_VIEW)
      || this.canViewPayables();
  }

  canManagePayablesPaid() {
    return this.checkPermission(MODULE_PAYABLES_PAID_MANAGE)
      || this.canManagePayables();
  }

  canViewPayablesPaid() {
    return this.canManagePayablesPaid()
      || this.checkPermission(MODULE_PAYABLES_PAID_VIEW)
      || this.canViewPayables();
  }

  canManagePayablesCredits() {
    return this.checkPermission(MODULE_PAYABLES_CREDITS_MANAGE)
      || this.canManagePayables();
  }

  canViewPayablesCredits() {
    return this.canManagePayablesCredits()
      || this.checkPermission(MODULE_PAYABLES_CREDITS_VIEW)
      || this.canViewPayables();
  }

  canManagePayablesDeductions() {
    return this.checkPermission(MODULE_PAYABLES_DEDUCTIONS_MANAGE)
      || this.canManagePayables();
  }

  canViewPayablesDeductions() {
    return this.canManagePayablesDeductions()
      || this.checkPermission(MODULE_PAYABLES_DEDUCTIONS_VIEW)
      || this.canViewPayables();
  }

  // Billing
  canManageBilling() {
    return this.checkPermission(MODULE_BILLING_MANAGE);
  }

  canViewBilling() {
    return this.canManageBilling() || this.checkPermission(MODULE_BILLING_VIEW);
  }

  canManageBillingInvoices() {
    return this.checkPermission(MODULE_BILLING_INVOICES_MANAGE)
      || this.canManageBilling();
  }

  canViewBillingInvoices() {
    return this.canManageBillingInvoices()
      || this.checkPermission(MODULE_BILLING_INVOICES_VIEW)
      || this.canViewBilling();
  }

  canManageBillingCredits() {
    return this.checkPermission(MODULE_BILLING_CREDITS_MANAGE)
      || this.canManageBilling();
  }

  canViewBillingCredits() {
    return this.canManageBillingCredits()
      || this.checkPermission(MODULE_BILLING_CREDITS_VIEW)
      || this.canViewBilling();
  }

  canManageBillingCharges() {
    return this.checkPermission(MODULE_BILLING_CHARGES_MANAGE)
      || this.canManageBilling();
  }

  canViewBillingCharges() {
    return this.canManageBillingCharges()
      || this.checkPermission(MODULE_BILLING_CHARGES_VIEW)
      || this.canViewBilling();
  }

  // Onboarding
  canManageOnboarding() {
    return this.checkPermission(MODULE_ONBOARDING_MANAGE);
  }

  canViewOnboarding() {
    return this.canManageOnboarding() || this.checkPermission(MODULE_ONBOARDING_VIEW);
  }

  canManageOnboardingGeneralLicensing() {
    return this.checkPermission(MODULE_ONBOARDING_GENERAL_LICENSING_MANAGE)
      || this.canManageOnboarding();
  }

  canViewOnboardingGeneralLicensing() {
    return this.canManageOnboardingGeneralLicensing()
      || this.checkPermission(MODULE_ONBOARDING_GENERAL_LICENSING_VIEW)
      || this.canViewOnboarding();
  }

  canManageDrainManualQueue() {
    return this.checkPermission(MODULE_ONBOARDING_GENERAL_LICENSING_DRAIN_MANUAL_QUEUE);
  }

  canManageOnboardingNurseLicensing() {
    return this.checkPermission(MODULE_ONBOARDING_NURSE_LICENSING_MANAGE)
      || this.canManageOnboarding();
  }

  canViewOnboardingNurseLicensing() {
    return this.canManageOnboardingNurseLicensing()
      || this.checkPermission(MODULE_ONBOARDING_NURSE_LICENSING_VIEW)
      || this.canViewOnboarding();
  }

  canManageOnboardingDocuments() {
    return this.checkPermission(MODULE_ONBOARDING_DOCUMENTS_MANAGE)
      || this.canManageOnboarding();
  }

  canViewOnboardingDocuments() {
    return this.canManageOnboardingDocuments()
      || this.checkPermission(MODULE_ONBOARDING_DOCUMENTS_VIEW)
      || this.canViewOnboarding();
  }

  canManageOnboardingDrugScreens() {
    return this.checkPermission(MODULE_ONBOARDING_DRUG_SCREENS_MANAGE)
      || this.canManageOnboarding();
  }

  canViewOnboardingDrugScreens() {
    return this.canManageOnboardingDrugScreens()
      || this.checkPermission(MODULE_ONBOARDING_DRUG_SCREENS_VIEW)
      || this.canViewOnboarding();
  }

  canManageOnboardingBackgroundChecks() {
    return this.checkPermission(MODULE_ONBOARDING_BACKGROUND_CHECKS_MANAGE)
      || this.canManageOnboarding();
  }

  canViewOnboardingBackgroundChecks() {
    return this.canManageOnboardingBackgroundChecks()
      || this.checkPermission(MODULE_ONBOARDING_BACKGROUND_CHECKS_VIEW)
      || this.canViewOnboarding();
  }

  canManageOnboardingPaymentsMethods() {
    return this.checkPermission(MODULE_ONBOARDING_PAYMENTS_METHODS_MANAGE)
      || this.canManageOnboarding();
  }

  canViewOnboardingPaymentsMethods() {
    return this.canManageOnboardingPaymentsMethods()
      || this.checkPermission(MODULE_ONBOARDING_PAYMENTS_METHODS_VIEW)
      || this.canViewOnboarding();
  }

  // Insights
  canManageInsight() {
    return this.checkPermission(MODULE_INSIGHTS_MANAGE);
  }

  canViewInsights() {
    return this.canManageInsight() || this.checkPermission(MODULE_INSIGHTS_VIEW);
  }

  canManageInsightsHours() {
    return this.checkPermission(MODULE_INSIGHTS_HOURS_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsHours() {
    return this.canManageInsightsHours()
      || this.checkPermission(MODULE_INSIGHTS_HOURS_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsRevenue() {
    return this.checkPermission(MODULE_INSIGHTS_REVENUE_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsRevenue() {
    return this.canManageInsightsRevenue()
      || this.checkPermission(MODULE_INSIGHTS_REVENUE_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsRegions() {
    return this.checkPermission(MODULE_INSIGHTS_REGIONS_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsRegions() {
    return this.canManageInsightsRegions()
      || this.checkPermission(MODULE_INSIGHTS_REGIONS_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsOnboarding() {
    return this.checkPermission(MODULE_INSIGHTS_ONBOARDING_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsOnboarding() {
    return this.canManageInsightsOnboarding()
      || this.checkPermission(MODULE_INSIGHTS_ONBOARDING_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsCommission() {
    return this.checkPermission(MODULE_INSIGHTS_COMMISSION_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsCommission() {
    return this.canManageInsightsCommission()
      || this.checkPermission(MODULE_INSIGHTS_COMMISSION_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsCreditLimits() {
    return this.checkPermission(MODULE_INSIGHTS_CREDIT_LIMITS_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsCreditLimits() {
    return this.canManageInsightsCreditLimits()
      || this.checkPermission(MODULE_INSIGHTS_CREDIT_LIMITS_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsFacilityMap() {
    return this.checkPermission(MODULE_INSIGHTS_FACILITY_MAP_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsFacilityMap() {
    return this.canManageInsightsFacilityMap()
      || this.checkPermission(MODULE_INSIGHTS_FACILITY_MAP_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsScheduledHours() {
    return this.checkPermission(MODULE_INSIGHTS_SCHEDULED_HOURS_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsScheduledHours() {
    return this.canManageInsightsScheduledHours()
      || this.checkPermission(MODULE_INSIGHTS_SCHEDULED_HOURS_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsWorkLogs() {
    return this.checkPermission(MODULE_INSIGHTS_WORK_LOGS_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsWorkLogs() {
    return this.canManageInsightsWorkLogs()
      || this.checkPermission(MODULE_INSIGHTS_WORK_LOGS_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsMessaging() {
    return this.checkPermission(MODULE_INSIGHTS_MESSAGING_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsMessaging() {
    return this.canManageInsightsMessaging()
      || this.checkPermission(MODULE_INSIGHTS_MESSAGING_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsResolution() {
    return this.checkPermission(MODULE_INSIGHTS_RESOLUTIONS_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsResolution() {
    return this.canManageInsightsResolution()
      || this.checkPermission(MODULE_INSIGHTS_RESOLUTIONS_VIEW)
      || this.canViewInsights();
  }

  canManageInsightsValidation() {
    return this.checkPermission(MODULE_INSIGHTS_VALIDATION_MANAGE)
      || this.canManageInsight();
  }

  canViewInsightsValidation() {
    return this.canManageInsightsValidation()
      || this.checkPermission(MODULE_INSIGHTS_VALIDATION_VIEW)
      || this.canViewInsights();
  }

  // Help
  canManageHelp() {
    return this.checkPermission(MODULE_HELP_MANAGE);
  }

  canViewHelp() {
    return this.canManageHelp() || this.checkPermission(MODULE_HELP_VIEW);
  }

  // Downloads
  canManageDownload() {
    return this.checkPermission(MODULE_DOWNLOAD_MANAGE);
  }

  canViewDownload() {
    return this.canManageDownload() || this.checkPermission(MODULE_DOWNLOAD_VIEW);
  }

  // Messaging
  canManageMessaging() {
    return this.checkPermission(MODULE_MESSAGING_MANAGE);
  }

  canViewMessaging() {
    return this.canManageMessaging() || this.checkPermission(MODULE_MESSAGING_VIEW);
  }

  // Settings
  canManageSettings() {
    return this.checkPermission(MODULE_SETTINGS_MANAGE);
  }

  canViewSettings() {
    return this.canManageSettings() || this.checkPermission(MODULE_SETTINGS_VIEW);
  }

  canManageSettingsSpecialties() {
    return this.checkPermission(MODULE_SETTINGS_SPECIALTIES_MANAGE)
      || this.canManageSettings();
  }

  canViewSettingsSpecialties() {
    return this.canManageSettingsSpecialties()
      || this.checkPermission(MODULE_SETTINGS_SPECIALTIES_VIEW)
      || this.canViewSettings();
  }

  canManageSettingsSkills() {
    return this.checkPermission(MODULE_SETTINGS_SKILLS_MANAGE)
      || this.canManageSettings();
  }

  canViewSettingsSkills() {
    return this.canManageSettingsSkills()
      || this.checkPermission(MODULE_SETTINGS_SKILLS_VIEW)
      || this.canViewSettings();
  }

  canManageSettingsCredentials() {
    return this.checkPermission(MODULE_SETTINGS_CREDENTIALS_MANAGE)
      || this.canManageSettings();
  }

  canViewSettingsCredentials() {
    return this.canManageSettingsCredentials()
      || this.checkPermission(MODULE_SETTINGS_CREDENTIALS_VIEW)
      || this.canViewSettings();
  }

  canViewSettingsCredentialExplainer() {
    return this.checkPermission(MODULE_SETTINGS_CREDENTIAL_EXPLAINER_VIEW);
  }

  canManageSettingsRegions() {
    return this.checkPermission(MODULE_SETTINGS_REGIONS_MANAGE)
      || this.canManageSettings();
  }

  canViewSettingsRegions() {
    return this.canManageSettingsRegions()
      || this.checkPermission(MODULE_SETTINGS_REGIONS_VIEW)
      || this.canViewSettings();
  }

  canManageSettingsHolidays() {
    return this.checkPermission(MODULE_SETTINGS_HOLIDAYS_MANAGE);
  }

  canViewSettingsHolidays() {
    return this.canManageSettingsHolidays()
      || this.checkPermission(MODULE_SETTINGS_HOLIDAYS_VIEW);
  }

  canManageSettingsMarketers() {
    return this.checkPermission(MODULE_SETTINGS_MARKETERS_MANAGE)
      || this.canManageSettings();
  }

  canViewSettingsMarketers() {
    return this.canManageSettingsMarketers()
      || this.checkPermission(MODULE_SETTINGS_MARKETERS_VIEW)
      || this.canViewSettings();
  }

  canManageSettingsArFactoring() {
    return this.checkPermission(MODULE_SETTINGS_AR_FACTORING_MANAGE)
      || this.canManageSettings();
  }

  canViewSettingsArFactoring() {
    return this.canManageSettingsArFactoring()
      || this.checkPermission(MODULE_SETTINGS_AR_FACTORING_VIEW)
      || this.canViewSettings();
  }

  canManageSettingsPayoutDeductions() {
    return this.checkPermission(MODULE_SETTINGS_PAYOUT_DEDUCTIONS_MANAGE)
      || this.canManageSettings();
  }

  canViewSettingsPayoutDeductions() {
    return this.canManageSettingsPayoutDeductions()
      || this.checkPermission(MODULE_SETTINGS_PAYOUT_DEDUCTIONS_VIEW)
      || this.canViewSettings();
  }

  canManageSettingsFacilityFeatureFlags() {
    return this.checkPermission(MODULE_SETTINGS_FACILITY_FEATURE_FLAG_MANAGE);
  }

  canViewSettingsFacilityFeatureFlags() {
    return this.canManageSettingsFacilityFeatureFlags()
      || this.checkPermission(MODULE_SETTINGS_FACILITY_FEATURE_FLAG_VIEW);
  }

  canManageSettingsRoles() {
    return this.checkPermission(MODULE_SETTINGS_ROLES_MANAGE);
  }

  canViewSettingsRoles() {
    return this.canManageSettingsRoles()
      || this.checkPermission(MODULE_SETTINGS_ROLES_VIEW);
  }

  canManageSettingsMisc() {
    return this.checkPermission(MODULE_SETTINGS_MISC_MANAGE);
  }

  canManageSettingsDemoGenerator() {
    return this.checkPermission(MODULE_SETTINGS_DEMO_GENERATOR_MANAGE);
  }

  canAccessLevel1Data() {
    return this.checkPermission(LEVEL_1);
  }

  canAccessLevel2Data() {
    return this.checkPermission(LEVEL_2);
  }

  canAccessLevel3Data() {
    return this.checkPermission(LEVEL_3);
  }

  canUseShiftOpeningModal() {
    return this.canManageShifts()
      || this.canManageShiftsRequestedShifts()
      || this.canManageShiftsUnresolvedShifts()
      || this.canManageSchedule()
      || this.canManageBilling()
      || this.canManageBillingInvoices()
      || this.canManagePayables()
      || this.canManagePayablesPaid()
      || this.canManagePayablesUnpaid()
      || this.canManageReports()
      || this.canManageReportsShiftsReport();
  }
}

export const employeeService = new EmployeeService();

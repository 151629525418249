/* eslint max-len: 0 */
import Enum from 'enum';
import { Resources as PaymentsAndBilling } from '@/services/shiftkeyAPI/resources/ResourcesPaymentsAndBilling';
import {
  Resources as ClientFacilityTeamShift
} from '@/services/shiftkeyAPI/resources/ResourcesClientFacilityTeamShift';
import { Resources as Credentialing } from '@/services/shiftkeyAPI/resources/ResourcesCredentialing';
import { Resources as Shift } from '@/services/shiftkeyAPI/resources/ResourcesShift';
import { Resources as Working } from '@/services/shiftkeyAPI/resources/ResourcesWorking';
import { Resources as Browsing } from '@/services/shiftkeyAPI/resources/ResourcesBrowsingV1';
import { Resources as Download } from '@/services/shiftkeyAPI/resources/ResourcesDownload';

const PaymentsAndBillingsRoutes = Object.fromEntries(PaymentsAndBilling.enums.map((e) => [e.key, e.value]));
const ClientFacilityTeamShiftRoutes = Object.fromEntries(ClientFacilityTeamShift.enums.map((e) => [e.key, e.value]));
const CredentialingRoutes = Object.fromEntries(Credentialing.enums.map((e) => [e.key, e.value]));
const ShiftRoutes = Object.fromEntries(Shift.enums.map((e) => [e.key, e.value]));
const WorkingRoutes = Object.fromEntries(Working.enums.map((e) => [e.key, e.value]));
const BrowsingRoutes = Object.fromEntries(Browsing.enums.map((e) => [e.key, e.value]));
const DownloadRoutes = Object.fromEntries(Download.enums.map((e) => [e.key, e.value]));

/**
 * Enum defining all available api v1 resources and their URL template
 */
export const Resources = new Enum({
  Analytics_GainedVsLostProviders: 'analytics/gained-vs-lost-providers',
  Analytics_UnAssignedShiftOpeningGeoLocations: 'analytics/shift-openings/unassigned/geolocations',
  ComputedTable: 'computed_tables/:computedTableId',
  ComputedTableRecompute: 'computed_tables/:computedTableId/recompute',
  Employee: 'employees/:employeeId',
  Employees: 'employees',
  EmployeeRoles: 'employees/roles',
  EmployeeRole: 'employees/roles/:roleId',
  EmployeePermissions: 'employees/permissions',
  EmployeeManagePermissions: 'employees/:employeeId/manage_permissions',
  EmployeesBatchReAssignFacilities: 'employees/:employeeId/batch_re_assign_facilities',
  FacilityMap: 'insights/facility_map_markers',
  HoursSummary: 'hours_summary',
  Holidays: 'holiday',
  Holiday: 'holiday/:holidayId',
  HolidaysFeatures: 'holiday/feature',
  InsightsMessaging: 'insights/messaging',
  InsightsRegions: 'insights/regions',
  InsightsResolutionsSummary: 'insights/resolutions_summary',
  LookerSsoEmbedUrl: 'looker/embed_sso_url',
  ProcessedWorkLogsSummary: 'insights/processed_work_logs_summary',
  Provider: 'providers/:providerId',
  ProviderExperience: 'providers/:providerId/experience',
  ProviderCredentialsSummary: 'providers/:providerId/credentials_summary',
  Providers: 'providers',
  ProviderSuspension: 'providers/:providerId/suspension',
  ProviderSuspensionHistory: 'providers/:providerId/suspension_history',
  RegionsShiftCounts: 'regions/shift_counts',
  RevenueFinancialSummary: 'revenue_financial_summary',
  ShiftOpening_Resolution: 'shift_openings/:shift_opening_id/resolution', // outdated?
  ShiftOpening_ShiftWorklog: 'shift_openings/:shift_opening_id/shift_worklog', // outdated?
  Territories: 'territories',
  TerritoryRvp: 'territories/:id/rvp',
  TerritoryStates: 'territories/:id/states',
  TimesheetRejectionNotes: 'timesheet_rejection_notes',
  User: 'user',
  UserAvailableRegions: 'user/available-regions',
  UserProfile: 'users/:user_id/profile',
  UserRestore: 'user/undo-deletion/:user_id',
  UserNotification: 'user/notifications',
  UserNotificationCheckForUnseen: 'user/notifications/check-for-unseen',
  UserNotificationMarkAllAsRead: 'user/notifications/mark-all-as-read',
  UserNotificationMarkAsRead: 'user/notifications/:notificationId/mark-as-read',
  UserNotificationUpdateLastSeenNotificationForUser: 'user/notifications/:notificationId/last-seen-notification',
  UserTeamAdministrator: 'user/teams/:teamId/administrator',
  UserTeamMember: 'user/teams/:teamId/member',
  UserSession: 'users/:user_id/session',
  VerifyPhone: 'verify-phone/:mode',
  ...PaymentsAndBillingsRoutes,
  ...ClientFacilityTeamShiftRoutes,
  ...CredentialingRoutes,
  ...ShiftRoutes,
  ...WorkingRoutes,
  ...BrowsingRoutes,
  ...DownloadRoutes,
});
